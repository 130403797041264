
import React, { Component } from 'react'
import { Map, TileLayer, Marker, Popup, CircleMarker } from 'react-leaflet'
import Control from 'react-leaflet-control';

import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import Add from '@material-ui/icons/Add';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import MyLocation from '@material-ui/icons/MyLocation';

import { connect } from 'react-redux';
import { updateMapCenter, updateMapZoom } from './actions/mapActions'
import { addPlace } from './actions/placesActions'

import PouchDB from 'pouchdb';

// https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-410450387
import L from 'leaflet';

let _ = require('lodash')

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
///////////////////////////////////////////////////////////////////////////////

const remote = "https://muellmap:p3tMc9py@couch.cassiciacum.de/places"

type State = {
  showMarker: bool,
  places: array
}

class MuellMap extends Component<{}, State> {
  state = {
    showMarker: false,
    currentMarkerDescription: "",
    places: [],
    synchronizationStatus: ""
  }

  forwardMapEvents = true

  componentDidMount() {
    this.sync()
    this.updatePlaces()
    setInterval(this.updatePlaces.bind(this), 60000)
  }


  showMarker() {
    let showMarker = this.state.showMarker;
    this.setState({showMarker: true})
  }

  savePlace(){
    let place = {
      timestamp: (new Date()).getTime(),
      place: {
        coordinates: this.props.mapState.center,
        description: this.state.currentMarkerDescription ? this.state.currentMarkerDescription : "keine Beschreibung"
      }
    }

    PouchDB("places").post(place)
    this.setState({showMarker: false, currentMarkerDescription: "", places: this.state.places.concat(place)})
  }

  updatePlaces() {
    PouchDB("places").allDocs({
      include_docs: true,
      attachments: true
    })
    .then( result => console.log("updatePlaces",result) || result.rows && this.setState({places: result.rows.map(row => row.doc)}) )
    .catch(function (err) {
      console.log(err);
    });
  }

  sync() {
    let that = this;

        PouchDB.sync(remote, 'places', {live: true, retry: true})
        .on('change', function (info) {
          // handle change

          PouchDB("places").allDocs({
            include_docs: true,
            attachments: true
          })
          .then( result => result.rows && that.setState({places: result.rows.map(row => row.doc)}) )
          .catch(function (err) {
            console.log(err);
          });

        }).on('paused', function () {
          // replication paused (e.g. replication up to date, user went offline)
          console.log("paused")
          that.setState({
            synchronizationStatus: "Marker synchronisiert"
          })
        }).on('active', function () {
          // replicate resumed (e.g. new changes replicating, user went back online)
          that.setState({
            synchronizationStatus: "Synchronisiere Marker"
          })
        }).on('denied', function (err) {
          // a document failed to replicate (e.g. due to permissions)
          console.log(err)
        }).on('complete', function (info) {
          // handle complete
          console.log(info)
        }).on('error', function (err) {
          // handle error
          console.log(err)
          that.setState({
            synchronizationStatus: "Fehler beim Synchronisieren." + err
          })
        });

  }

  getGeolocation() {
    if ( "geolocation" in navigator ) {
      navigator.geolocation.getCurrentPosition(
        position => {

            this.forwardMapEvents = false

            this.props.onUpdateMapCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            })

            setTimeout(() => this.forwardMapEvents = true, 250)
        }
      )
    }
  }


  render() {
    const places = this.state.places
    const marker = <Marker position={this.props.mapState.center}>
      <Popup>
          <TextField
            multiline
            rows="4"
            style={{width:200, margin: 20}}
            label='Beschreibung (optional)'
            variant='outlined'
            value={this.state.currentMarkerDescription}
            onChange={(e) => this.setState({currentMarkerDescription: e.target.value})}
            ></TextField>
      </Popup>
    </Marker>
    const circles = places
      .map( p => <CircleMarker key={_.uniqueId()} center={p.place.coordinates} radius={5}>
      <Popup>
        {p.place.description}
      </Popup>
    </CircleMarker>)

    return (
      <div style={{height: window.innerHeight, width: window.innerWidth}}>
        <Map
          center={this.props.mapState.center}
          zoom={this.props.mapState.zoom}
          style={{height: window.innerHeight, width: window.innerWidth}}
          onMove={(e) => this.forwardMapEvents && this.props.onUpdateMapCenter(e.target.getCenter())}
          onZoom={(e) => this.forwardMapEvents && this.props.onUpdateMapZoom(e.target.getZoom())}
        >
          <TileLayer
            attribution={this.state.synchronizationStatus}
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

        {this.state.showMarker ? marker : ""}
        {circles}

          <Control position='bottomright'>

            { !this.state.showMarker

              ? <Fab
                  color='secondary'
                  onClick={() => this.showMarker()}
                >
                  <Add/>
                </Fab>

              : <div>
                  <Fab
                    color='secondary'
                    onClick={() => this.savePlace()}
                  >
                    <Save/>
                  </Fab>
                  <Fab
                      color='secondary'
                      onClick={() => this.setState({showMarker: false})}
                    >
                      <Close/>
                    </Fab>
                </div>

            }

          </Control>

          <Control position="bottomleft">
            <Fab onClick={() => this.getGeolocation()}>
              <MyLocation />
            </Fab>
          </Control>

        </Map>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  mapState: state.mapState,
  places: state.places
});
const mapDispatchToProps = dispatch => ({
  onUpdateMapCenter: center => dispatch(updateMapCenter(center)),
  onUpdateMapZoom: zoom => dispatch(updateMapZoom(zoom)),
  onAddPlace: place => dispatch(addPlace(place))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MuellMap);
