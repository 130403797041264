export const UPDATE_MAP_CENTER = "map:update_map_center"
export const UPDATE_MAP_ZOOM = "map:update_map_zoom"

export const updateMapCenter = center => {
  return {
    type: UPDATE_MAP_CENTER,
    payload: {
      center: center
    }
  }
}

export const updateMapZoom = zoom => {
  return {
    type: UPDATE_MAP_ZOOM,
    payload: {
      zoom: zoom
    }
  }
}
