import { createStore, combineReducers } from 'redux';
import { mapReducer } from './reducers/mapReducer'
import { placesReducer } from './reducers/placesReducer'

const rootReducer = combineReducers({
  mapState: mapReducer,
  places: placesReducer
})

const store = createStore(rootReducer)

export default store
