import { ADD_PLACE } from "../actions/placesActions"

export function placesReducer(state=[], action) {
  switch ( action.type ) {
    case ADD_PLACE: {
      return state.concat(action.payload)
    }
    default: { return state }
  }
}
