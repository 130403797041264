export const ADD_PLACE = "place:add_place"


export const addPlace = place => {
  return {
    type: ADD_PLACE,
    payload: {
      timestamp: (new Date()).getTime(),
      place: place
    }
  }
}
