import React from 'react';
import logo from './logo.svg';
import './App.css';
import MuellMap from './Map';

function App() {
  return (
    <div className="App" style={{height: window.innerHeight, width: window.innerWidth}}>
      <MuellMap/>
    </div>
  );
}

export default App;
