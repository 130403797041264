import { UPDATE_MAP_CENTER } from '../actions/mapActions'
import { UPDATE_MAP_ZOOM } from '../actions/mapActions'

const initialMapState = {
  center: {
    lat: 52.24506,
    lng: 13.08046,
  },
  zoom: 13
}

export function mapReducer(state=initialMapState, action) {
  switch ( action.type ) {
    case UPDATE_MAP_CENTER: { return applyUpdateMapCenter(state, action) }
    case UPDATE_MAP_ZOOM: { return applyUpdateMapZoom(state, action) }
    default: return state
  }
}

function applyUpdateMapCenter(state, action) {
  return Object.assign({}, state, {center: action.payload.center})
}

function applyUpdateMapZoom(state, action) {
  return Object.assign({}, state, {zoom: action.payload.zoom})
}
